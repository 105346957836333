<template>
  <div class="app-header">
    <div
        @click="redirect('https://us.louisvuitton.com', true)"
        class="app-header-arrow">
      <svg width="21" height="50" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.32 8.63999L0 4.32L4.32 0L5.55 1.23L2.46 4.32L5.55 7.40999L4.32 8.63999Z" fill="black"/>
        <path d="M20.42 3.45001H1.22998V5.19001H20.42V3.45001Z" fill="black"/>
      </svg>
    </div>
    <div @click="redirect('https://us.louisvuitton.com', false)" class="app-header-title"><span style="cursor: pointer;">louis vuitton</span></div>
  </div>
</template>

<script>
export default {
  methods: {
    redirect(url, newTab) {
      newTab ?
          window.location = url :
          window.open(url);
    }
  }
}
</script>

<style scoped>
.app-header {
  position: fixed;
  top: 0;
  background: #ffffff;
  width: 100%;
  height: 50px;
}
.app-header-title {
  line-height: 50px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  font-family: LouisVuitton-Demi, sans-serif;
}
.app-header-arrow {
  position: absolute;
  left: 20px;
}
.app-header-arrow:hover svg {
  cursor: pointer;
}
.app-header-arrow:hover svg path {
  fill: gray;
}
</style>
