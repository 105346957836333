import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import App from './App.vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import routes from './routes';

const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.use(VueRouter);
Vue.use(VueGtag, {
  config: {
    id: 'UA-135880969-7',
    params: {
      custom_map: {
        'dimension2': 'cd2',
        'dimension4': 'cd4',
        'dimension5': 'cd5',
        'dimension9': 'cd9',
        'dimension12': 'cd12',
        'dimension27': 'cd27',
        'dimension84': 'cd84',
      }
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === 'products' && !to.params.quizFinished) {
    return next({path: '/'})
  }

  return next()
});

import store from './store';
import Vuex from 'vuex';
Vue.use(Vuex);

export const storeInstance = new Vuex.Store(store());

// import directives from './directives';
// for (let directive in directives) {
//   Vue.directive(directive, directives[directive]);
// }

new Vue({
  render: h => h(App),
  router,
  el: '#app',
  store: storeInstance,
});
