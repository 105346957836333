<template>
  <div>
    <div class="questions">
      <div v-show="!quizFinished"
           class="question flex-grow-1"
           @mouseenter="toggleAnimation(1, true)"
           @mouseleave="toggleAnimation(1, false)"
           @click="submitAnswer(question, 1)">
        <img
            v-show="!shouldAnimateAnswer1"
            :class="`img-${question.answers[0].name}`"
            :src="`/images/answers/${question.answers[0].name}.png`"
            alt="Answer 1">
        <img
            v-show="shouldAnimateAnswer1"
            :class="`img-${question.answers[0].name}`"
            :src="`/images/answers/${question.answers[0].name}.gif`"
            @mouseenter="resetAnimation($event, `/images/answers/${question.answers[0].name}.gif`)"
            alt="Answer 1">
      </div>
      <div v-show="!quizFinished"
           class="question flex-grow-1"
           @mouseenter="toggleAnimation(2, true)"
           @mouseleave="toggleAnimation(2, false)"
           @click="submitAnswer(question, 2)">
        <img
            v-show="!shouldAnimateAnswer2"
            :class="`img-${question.answers[1].name}`"
            :src="`/images/answers/${question.answers[1].name}.png`"
            alt="Answer 2">
        <img
            v-show="shouldAnimateAnswer2"
            :class="`img-${question.answers[1].name}`"
            :src="`/images/answers/${question.answers[1].name}.gif`"
            @mouseenter="resetAnimation($event, `/images/answers/${question.answers[1].name}.gif`)"
            alt="Answer 2">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['question'],
  data() {
    return {
      shouldAnimateAnswer1: false,
      shouldAnimateAnswer2: false
    }
  },
  created() {
    if(this.$store.getters.currentQuestion.id == 2) {
      this.$gtag.pageview({
        'page_path': '/welvmomshomepage',
        'page_title': `homepage`,
        'cd2': 'louisvuitton.com',
        'cd4': 'site',
        'cd5': 'us',
        'cd9': 'not_logged_user',
        'cd12': 'vue_stack',
        'cd84': 'welvmoms_module'
      });
    }
  },
  mounted() {
    this.disableScroll();
  },
  beforeDestroy() {
    this.disableScroll(false);
  },
  computed: {
    quizFinished() {
      return this.$store.state.isQuizFinished;
    },
    isTouchDevice() {
      return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
    }
  },
  methods: {
    submitAnswer(question, answerId) {
      const generatedById = parseInt(`${question.id}${answerId}`);
      const newQuestion = this.$store.getters.generatedQuestion(generatedById);

      this.$store.dispatch('submitAnswer', {questionId: question.id, answerId})
          .then(() => {
            // analytics - select answer
            this.$gtag.event('quiz selection', {
              'event_category': 'welvmoms',
              'event_label': question.answers[answerId - 1].name,
              'event_action': 'quiz_answer',
              'cd2': 'louisvuitton.com',
              'cd4': 'site',
              'cd5': 'us',
              'cd9': 'not_logged_user',
              'cd12': 'vue_stack',
              //'cd53': '<GA cid>', we're not using User IDs
              //'cd51': '<ATG ID>', no ATG IDs
              //'cd131': '<Dream ID>', no CRM sessions
            });
            if (question.number < 5) {
              // analytics - step of quiz
              this.$gtag.pageview({
                'page_path': `welvmoms_step_${question.number + 1}`,
                'page_title': `Step ${question.number + 1}`,
                'dp': `welvmoms_step_${question.number + 1}`,
                'cd84': 'welvmoms_module',
                'cd2': 'louisvuitton.com',
                'cd4': 'site',
                'cd5': 'us',
                'cd9': 'not_logged_user',
                'cd12': 'vue_stack',
              });
            }

            if (question.number === 5) {
              this.$store.dispatch('toggleQuizFinished').then(() => {
                this.$router.push({
                  name: 'products',
                  params: {
                    quizFinished: this.quizFinished,
                    answers: this.$store.getters.allAnswers
                  },
                }).catch(() => {});
              });
            } else {
              this.changeQuestion(newQuestion.id)
            }
          });
    },
    changeQuestion(questionId) {
      this.$store.dispatch('generateNextQuestion', questionId);
    },
    toggleAnimation(answerNumber, shouldAnimate) {
      if (window.innerWidth <= 768) {
        this.shouldAnimateAnswer1 = false;
        this.shouldAnimateAnswer2 = false;
        return;
      }
      if (answerNumber === 1) {
        this.shouldAnimateAnswer1 = !!shouldAnimate;
      }
      if (answerNumber === 2) {
        this.shouldAnimateAnswer2 = !!shouldAnimate;
      }
    },
    resetAnimation(e, src) {
      e.currentTarget.setAttribute('src', "''");
      e.currentTarget.setAttribute('src', src);
    },
    disableScroll(enable = true) {
      const el = document.querySelector('#app');
      let scrollPosition = 0;

      if (enable) {
        scrollPosition = window.pageYOffset;
        el.style.overflow = 'hidden';
        el.style.position = 'fixed';
        el.style.top = `-${scrollPosition}px`;
        el.style.width = '100%';
      } else {
        el.style.removeProperty('overflow');
        el.style.removeProperty('position');
        el.style.removeProperty('top');
        el.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
      }
    }
  }
}
</script>

<style scoped>
.question:hover {
  cursor: pointer;
}
.question:active {
  /*color: #ffffff;*/
}

@media (min-width: 481px) {
  .questions {
    display: flex;
    margin-top: 30vh;
  }
  .question {
    position: relative;
    height: 35vh;
    width: 50%;
  }
  .question img {
    position: absolute;
    width: 90%;
  }
  .img-playful {
    top: -3vw;
    padding-right: 4vw;
    left: 0;
  }
  .img-elegant {
    top: -3vw;
    right: 0;
  }
  .img-bold {
    bottom: 3vw;
    left: 10vw;
    padding-right: 23vw;
  }
  .img-minimalist {
    bottom: 4.5vw;
    right: 7vw;
  }
  .img-statement {
    bottom: 3.5vw;
    left: 0;
  }
  .img-classic {
    bottom: 3.5vw;
    padding-left: 8vw;
    right: 0;
  }
  .img-country {
    bottom: -13.5vw;
    left: 0;
    width: 100% !important;
  }
  .img-city {
    bottom: 4vw;
    right: 0;
    padding-left: 20vw;
  }
  .img-day {
    bottom: 0.5vw;
    left: 0;
    padding-right: 10vw;
  }
  .img-evening {
    bottom: -4.5vw;
    right: 0;
  }
}

@media (max-width: 480px) {
  .questions {
    flex-direction: column;
    margin-top: 15vh;
  }
  .question {
    height: 35vh;
    width: 100%;
  }
  .question img {
    width: 100%;
  }
  .img-playful {
    padding-top: 10%;
    padding-right: 15vw;
  }
  .img-elegant {
    padding-left: 15vw;
  }
  .img-bold {
    padding-top: 10vh;
    padding-left: 30vw;
    padding-right: 30vw;
  }
  .img-minimalist {
    padding: 0 10vw;
  }
  .img-statement {
    padding-top: 5vh;
    padding-right: 15vw;
  }
  .img-classic {
    padding-left: 15vw;
  }
  .img-country {
    padding-top: 30vw;
    padding-right: 15vw;
  }
  .img-city {
    padding-left: 50vw;
  }
  .img-day {
    padding-right: 35vw;
    padding-top: 5vh;
  }
  .img-evening {
    padding-top: 5vh;
    padding-left: 15vw;
  }
}
</style>
