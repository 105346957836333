export default {
    products: [
        {
            id: "M56966",
            name: "SPEEDY BANDOULIÈRE 30",
            price: "$4,150 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/speedy-bandouliere-30-other-leathers-nvprod2420004v",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-speedy-bandoulière-30-other-leathers-handbags--M56966_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 1
        },
        {
            id: "1A8GC8",
            name: "STARBOARD FLAT ESPADRILLE",
            price: "$740 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/starboard-flat-espadrille-nvprod2680204v",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-starboard-flat-espadrille-shoes--AK9L3PAG14_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 2
        },
        {
            id: "1A8NMQ",
            name: "STELLER SNEAKER",
            price: "$965 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/stellar-sneaker-nvprod2550142v#1A8NMQ",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX02_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 3
        },
        {
            id: "1A8LM8",
            name: "SINCE 1854 HOODED SILK PARKA",
            price: "$4,300 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/since-1854-hooded-silk-parka-nvprod2470059v",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-hooded-silk-parka-ready-to-wear--FKOW22ZPC900_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 4
        },
        {
            id: "M44925",
            name: "ONTHEGO GM",
            price: "$3,350 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/onthego-gm-monogram-empreinte-nvprod2010013v",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-onthego-gm-monogram-empreinte-leather-handbags--M44925_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 5
        },
        {
            id: "M6042E",
            name: "ESSENTIAL V BRACELET",
            price: "$290 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/essential-v-bracelet-011992",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-essential-v-bracelet-autres-toiles-monogram-leather-bracelets--M6042E_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 6
        },
        {
            id: "MP2823",
            name: "SINCE 1854 MONOGRAM SHAWL",
            price: "$730 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/since-1854-monogram-shawl-nvprod2520002v",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-since-1854-monogram-shawl-scarves-and-shawls--MP2823_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 7
        },
        {
            id: "M80093",
            name: "SCALA MINI POUCH",
            price: "$1,890 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/scala-mini-pouch-nvprod2650001v#M80093",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-scala-mini-pouch-mahina-small-leather-goods--M80093_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 8
        },
        {
            id: "M52880",
            name: "LOCKY BB",
            price: "$2,370 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/locky-bb-epi-nvprod1280178v",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-locky-bb-epi-leather-handbags--M52880_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 9
        },
        {
            id: "M6758F",
            name: "SAY YES BRACELET",
            price: "$390 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/say-yes-bracelet-monogram-nvprod2680134v#M6758F",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-say-yes-bracelet-monogram-fashion-jewelry--M6758E_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 10
        },
        {
            id: "1A8M5I",
            name: "GAME ON TROMPE L’OEIL JACQUARD KNIT POLO DRESS",
            price: "$2,700 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/game-on-trompe-l-oeil-jacquard-knit-polo-dress-nvprod2550044v",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-trompe-l’oeil-jacquard-knit-polo-dress-ready-to-wear--FKKD97AD3900_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 11
        },
        {
            id: "M76671",
            name: "SECRET JUNGLE BANDEAU",
            price: "$210 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/secret-jungle-bandeau-nvprod2700224v",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-secret-jungle-bandeau-silk-squares-and-bandeaus--M76671_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 12
        },
        {
            id: "M45205",
            name: "MONTSOURIS BACKPACK",
            price: "$2,700 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/montsouris-pm-nvprod2350004v#M45205",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45205_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 13
        },
        {
            id: "1A8GFI",
            name: "REVIVAL MULE",
            price: "$830 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/revival-mule-nvprod2680219v#1A8GFI",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-revival-mule-shoes--AKVH3JLK02_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 14
        },
        {
            id: "1A8LMP",
            name: "BELTED MONOGRAM JACQUARD STRAIGHT-CUT DRESS",
            price: "$3,250 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/belted-monogram-jacquard-straight-cut-dress-nvprod2550296v",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-belted-monogram-jacquard-straight-cut-dress-ready-to-wear--FKDR15QS3130_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 15
        },
        {
            id: "M76681",
            name: "LV BESTIARY BB BANDEAU",
            price: "$200 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-bestiary-bb-bandeau-nvprod2700225v",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-bestiary-bb-bandeau-silk-squares-and-bandeaus--M76681_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 16
        },
        {
            id: "1A8KQA",
            name: "LINGERIE-INSPIRED FITTED DRESS",
            price: "$2,030 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/lingerie-inspired-fitted-dress-nvprod2550294v",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lingerie-inspired-fitted-dress-ready-to-wear--FKDR09NHY900_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 17
        },
        {
            id: "1A8O0L",
            name: "SOFIA FLAT MULE",
            price: "$1,010 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/sofia-flat-mule-nvprod2550181v#1A8O0L",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sofia-flat-mule-shoes--AKPH2APC02_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 18
        },
        {
            id: "Q95443",
            name: "IDYLLE BLOSSOM XL BRACELET, 3 GOLDS AND DIAMONDS",
            price: "$5,650 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/idylle-blossom-xl-bracelet-3-golds-and-diamonds-012834",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-xl-bracelet-3-golds-and-diamonds-jewelry--Q95443_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 19
        },
        {
            id: "M53191",
            name: "TRUNK CLUTCH",
            price: "$3,600 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/trunk-clutch-epi-nvprod1040054v#M53191",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-trunk-clutch-epi-leather-handbags--M53191_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 20
        },
        {
            id: "M64858",
            name: "BLOOMING SUPPLE BRACELET",
            price: "$520.00 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/blooming-supple-bracelet-nvprod710115v ",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-blooming-supple-bracelet-fashion-jewelry--M64858_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 21
        },
        {
            id: "1A8NLE",
            name: "STELLAR SNEAKER",
            price: "$965 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/stellar-sneaker-nvprod2550142v#1A8NLE",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-shoes--AK2U4ATX14_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 22
        },
        {
            id: "M45199",
            name: "CARRY IT",
            price: "$1,800 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/carry-it-monogram-nvprod2010019v",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-carry-it-monogram-handbags--M45199_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 23
        },
        {
            id: "1A8NED",
            name: "STELLAR OPEN BACK SNEAKER",
            price: "$875",
            product_url: "https://us.louisvuitton.com/eng-us/products/stellar-open-back-sneaker-nvprod2600023v#1A8NED",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-open-back-sneaker-shoes--AK2U5APC01_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 24
        },
        {
            id: "M45548",
            name: "LVXUF POCHETTE ACCESSOIRES",
            price: "$1,550 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/lvxuf-pochette-accessoires-nvprod2630038v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lvxuf-pochette-accessoires-autres-toiles-monogram-handbags--M45548_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 25
        },
        {
            id: "1A8LSE",
            name: "GAME ON MONOGRAM FLOWER CUT OUT A-LINE DRESS",
            price: "$3,950 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/game-on-monogram-flower-cut-out-a-line-dress-nvprod2550031v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-monogram-flower-cut-out-a-line-dress-ready-to-wear--FKDR97XCM502_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 26
        },
        {
            id: "M76109",
            name: "BUBBLEGRAM BB BANDEAU",
            price: "$200 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/bubblegram-bb-bandeau-nvprod2050039v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bubblegram-bb-bandeau-silk-squares-and-bandeaus--M76109_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 27
        },
        {
            id: "M76635",
            name: "CHALE MNG ROUGE",
            price: "$640 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/monogram-shawl-001054#M76635",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shawl-scarves-and-shawls--M76635_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 28
        },
        {
            id: "M57450",
            name: "GAME ON PANAME SET",
            price: "$2,890 ",
            product_url: "https://us.louisvuitton.com/eng-us/products/game-on-paname-set-nvprod2440104v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-game-on-paname-set-autres-toiles-monogram-handbags--M57450_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 29
        },
        {
            id: "M57691",
            name: "NÉONOÉ BB",
            price: "$2,510",
            product_url: "https://us.louisvuitton.com/eng-us/products/neonoe-bb-epi-nvprod2790025v#M57691",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-néonoé-bb-epi-leather-handbags--M57691_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 30
        },
        {
            id: "Q93809",
            name: "LV VOLT UPSIDE DOWN PENDANT, PINK GOLD",
            price: "$2,780",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-volt-upside-down-pendant-pink-gold-nvprod2330039v",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-upside-down-pendant-pink-gold-jewelry--Q93809_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 31
        },
        {
            id: "M45410",
            name: "MONTSOURIS BACKPACK",
            price: "$2,700",
            product_url: "https://us.louisvuitton.com/eng-us/products/montsouris-pm-nvprod2350004v",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-montsouris-backpack-monogram-empreinte-leather-handbags--M45410_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 32
        },
        {
            id: "M76353",
            name: "MONOGRAM SHINE SHAWL",
            price: "$730",
            product_url: "https://us.louisvuitton.com/eng-us/products/monogram-shine-shawl-001341#M76353",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M76353_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 33
        },
        {
            id: "M57864",
            name: "LOUIS VUITTON NEW WAVE MULTI-POCHETTE",
            price: "$2,300",
            product_url: "https://us.louisvuitton.com/eng-us/products/louis-vuitton-new-wave-multi-pochette-h24-nvprod2130117v",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-louis-vuitton-new-wave-multi-pochette-h24-handbags--M57864_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 34
        },
        {
            id: "M61873",
            name: "ZIPPY WALLET",
            price: "$965",
            product_url: "https://us.louisvuitton.com/eng-us/products/zippy-wallet-epi-014353#M61873",
            tag: "playful",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-zippy-wallet-epi-leather-small-leather-goods--M61873_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 35
        },
        {
            id: "M80168",
            name: "PETIT SAC PLAT",
            price: "$1,680",
            product_url: "https://us.louisvuitton.com/eng-us/products/petit-sac-plat-epi-nvprod2370038v#M80168",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-petit-sac-plat-epi-leather-small-leather-goods--M80168_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 36
        },
        {
            id: "Q9O58E",
            name: "LV VOLT ONE BAND RING, YELLOW GOLD AND DIAMOND",
            price: "$5,300",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-volt-one-band-ring-yellow-gold-and-diamond-nvprod2330001v",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-one-band-ring-yellow-gold-and-diamond-jewelry--Q9O58E_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 37
        },
        {
            id: "Z1432E",
            name: "SAILOR & LULA PILOT SUNGLASSES",
            price: "$765",
            product_url: "https://us.louisvuitton.com/eng-us/products/sailor-lula-pilot-sunglasses-nvprod2770003v#Z1432E",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-sailor-lula-pilot-sunglasses-sunglasses--Z1432W_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 38
        },
        {
            id: "LP0175",
            name: "CALIFORNIA DREAM",
            price: "$265",
            product_url: "https://us.louisvuitton.com/eng-us/products/california-dream-nvprod2210033v",
            tag: "bold",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-california-dream-fragrances--LP0175_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 39
        },
        {
            id: "M80483",
            name: "EASY POUCH ON STRAP",
            price: "$1,590",
            product_url: "https://us.louisvuitton.com/eng-us/products/easy-pouch-on-strap-nvprod2800080v#M80483",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-easy-pouch-on-strap-epi-leather-small-leather-goods--M80483_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 40
        },
        {
            id: "M76776",
            name: "MONOGRAM GRADIENT SQUARE",
            price: "$485",
            product_url: "https://us.louisvuitton.com/eng-us/products/monogram-gradient-square-nvprod2810070v#M76776",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-gradient-square-silk-squares-and-bandeaus--M76776_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 41
        },
        {
            id: "M55950",
            name: "LV PONT 9",
            price: "$3,900",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-pont-9-calfskin-leather-nvprod2290051v#M55950",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-pont-9-calfskin-leather-handbags--M55950_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 42
        },
        {
            id: "Q96667",
            name: "COLOR BLOSSOM BB STAR EAR STUDS, PINK GOLD, PINK MOTHER OF PEARL AND DIAMONDS",
            price: "$3,900",
            product_url: "https://us.louisvuitton.com/eng-us/products/color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-nvprod1060046v",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-star-ear-studs-pink-gold-pink-mother-of-pearl-and-diamonds-jewelry--Q96667_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 43
        },
        {
            id: "M60915",
            name: "CLÉMENCE WALLET",
            price: "$680",
            product_url: "https://us.louisvuitton.com/eng-us/products/clemence-wallet-epi-010044",
            tag: "minimalist",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-clémence-wallet-epi-leather-small-leather-goods--M60915_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 44
        },
        {
            id: "Q95596",
            name: "COLOR BLOSSOM BB MULTI-MOTIFS BRACELET, PINK GOLD, WHITE MOTHER-OF-PEARL AND DIAMONDS",
            price: "$6,700",
            product_url: "https://us.louisvuitton.com/eng-us/products/color-blossom-bb-multi-motif-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-nvprod900064v",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-color-blossom-bb-multi-motifs-bracelet-pink-gold-white-mother-of-pearl-and-diamonds-jewelry--Q95596_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 45
        },
        {
            id: "M57118",
            name: "VANITY PM",
            price: "$2,970",
            product_url: "https://us.louisvuitton.com/eng-us/products/vanity-pm-monogram-ink-nvprod2400036v",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-ink-handbags--M57118_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 46
        },
        {
            id: "M53821",
            name: "ROSE DES VENTS PM",
            price: "$4,350",
            product_url: "https://us.louisvuitton.com/eng-us/products/rose-des-vents-pm-high-end-leathers-nvprod1420198v#M53821",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-pm-high-end-leathers-handbags--M53821_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 47
        },
        {
            id: "LP0005",
            name: "ROSE DES VENTS",
            price: "$265",
            product_url: "https://us.louisvuitton.com/eng-us/products/rose-des-vents-014422",
            tag: "elegant",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-rose-des-vents-fragrances--LP0005_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 48
        },
        {
            id: "1A8KU6",
            name: "METALLIC MONOGRAM LIGHTWEIGHT HOODED PARKA",
            price: "$4,750",
            product_url: "https://us.louisvuitton.com/eng-us/products/metallic-monogram-lightweight-hooded-parka-nvprod2550366v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-metallic-monogram-lightweight-hooded-parka-ready-to-wear--FKOW20HQO105_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 49
        },
        {
            id: "Q96971",
            name: "LV VOLT CURB CHAIN EARRINGS, YELLOW GOLD",
            price: "$5,950",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-volt-curb-chain-earrings-yellow-gold-nvprod2330029v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-volt-curb-chain-earrings-yellow-gold-jewelry--Q96971_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 50
        },
        {
            id: "N60285",
            name: "KIRIGAMI NECKLACE",
            price: "$690",
            product_url: "https://us.louisvuitton.com/eng-us/products/kirigami-necklace-damier-ebene-nvprod1720135v",
            tag: "statement",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-kirigami-necklace-damier-ebene-small-leather-goods--N60285_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 51
        },
        {
            id: "Q96579",
            name: "EMPREINTE EAR STUDS, PINK GOLD",
            price: "$1,700",
            product_url: "https://us.louisvuitton.com/eng-us/products/empreinte-ear-studs-pink-gold-nvprod1000067v",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-empreinte-ear-studs-pink-gold-jewelry--Q96579_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 52
        },
        {
            id: "1A4VTE",
            name: "STELLAR SNEAKER BOOT",
            price: "$875",
            product_url: "https://us.louisvuitton.com/eng-us/products/stellar-sneaker-boot-nvprod1330037v#1A4VTE",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-stellar-sneaker-boot-shoes--AG2U6AMDBJ_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 53
        },
        {
            id: "Z1484W",
            name: "LV BLADE SUNGLASSES",
            price: "$730",
            product_url: "https://us.louisvuitton.com/eng-us/products/lv-blade-sunglasses-nvprod2780040v#Z1484W",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-lv-blade-sunglasses-sunglasses--Z1484W_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 54
        },
        {
            id: "M62017",
            name: "KEY POUCH",
            price: "$475",
            product_url: "https://us.louisvuitton.com/eng-us/products/key-pouch-monogram-empreinte-009134",
            tag: "country",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-key-pouch-monogram-empreinte-leather-key-holders-and-bag-charms--M62017_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 55
        },
        {
            id: "M44812",
            name: "MONOGRAM EMPREINTE BUMBAG",
            price: "$1,990",
            product_url: "https://us.louisvuitton.com/eng-us/products/monogram-empreinte-bumbag-monogram-empreinte-nvprod1770362v",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-empreinte-bumbag-monogram-empreinte-leather-handbags--M44812_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 56
        },
        {
            id: "1A3CW4",
            name: "RUN AWAY SNEAKER",
            price: "$875",
            product_url: "https://us.louisvuitton.com/eng-us/products/run-away-sneaker-nvprod470025v",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-run-away-sneaker-shoes--AD9U1ASC02_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 57
        },
        {
            id: "LP0084",
            name: "LE JOUR SE LÈVE",
            price: "$265",
            product_url: "https://us.louisvuitton.com/eng-us/products/le-jour-se-leve-nvprod880010v",
            tag: "day",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-le-jour-se-lève-fragrances--LP0084_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 58
        },
        {
            id: "LP0001",
            name: "APOGÉE",
            price: "$265",
            product_url: "https://us.louisvuitton.com/eng-us/products/apogee-014418",
            tag: "evening",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-apogée-fragrances--LP0001_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 59
        },
        {
            id: "M57070",
            name: "BELLA",
            price: "$3,600",
            product_url: "https://us.louisvuitton.com/eng-us/products/bella-mahina-nvprod2410007v#M57070",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-bella-mahina-handbags--M57070_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 60
        },
        {
            id: "M76649",
            name: "ULTIMATE MONOGRAM SQUARE",
            price: "$485",
            product_url: "https://us.louisvuitton.com/eng-us/products/ultimate-monogram-square-nvprod2530001v#M76649",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-ultimate-monogram-square-silk-squares-and-bandeaus--M76649_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 61
        },
        {
            id: "Q96837",
            name: "IDYLLE BLOSSOM HOOPS, WHITE GOLD AND DIAMONDS",
            price: "$12,000",
            product_url: "https://us.louisvuitton.com/eng-us/products/idylle-blossom-hoops-white-gold-and-diamonds-nvprod2070008v",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-idylle-blossom-hoops-white-gold-and-diamonds-jewelry--Q96837_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 62
        },
        {
            id: "LP0083",
            name: "ATTRAPE-RÊVES",
            price: "$265",
            product_url: "https://us.louisvuitton.com/eng-us/products/attrape-reves-nvprod1160017v",
            tag: "city",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-attrape-rêves-fragrances--LP0083_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 63
        },
        {
            id: "M75122",
            name: "MONOGRAM SHINE SHAWL",
            price: "$730",
            product_url: "https://us.louisvuitton.com/eng-us/products/monogram-shine-shawl-001341#M75122",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-monogram-shine-shawl-obsoletes-do-not-touch--M75122_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 64
        },
        {
            id: "QBB166",
            name: "TAMBOUR MONOGRAM 34MM",
            price: "$6,155",
            product_url: "https://us.louisvuitton.com/eng-us/products/tambour-monogram-34mm-nvprod2780014v",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-tambour-monogram-34mm-watches--QBB166_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 65
        },
        {
            id: "M45523",
            name: "MAIDA HOBO",
            price: "$3,200",
            product_url: "https://us.louisvuitton.com/eng-us/products/maida-hobo-monogram-empreinte-nvprod2410004v#M45523",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-maida-hobo-monogram-empreinte-leather-handbags--M45523_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 66
        },
        {
            id: "M57559",
            name: "TWIST MM",
            price: "$4,150",
            product_url: "https://us.louisvuitton.com/eng-us/products/twist-mm-epi-nvprod2630044v#M57559",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-twist-mm-epi-leather-handbags--M57559_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 67
        },
        {
            id: "M45608",
            name: "VANITY PM",
            price: "$2,930",
            product_url: "https://us.louisvuitton.com/eng-us/products/vanity-pm-monogram-empreinte-nvprod2630051v#M45608",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vanity-pm-monogram-empreinte-leather-handbags--M45608_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 68
        },
        {
            id: "M67839",
            name: "VAVIN CHAIN WALLET",
            price: "$1,870",
            product_url: "https://us.louisvuitton.com/eng-us/products/vavin-chain-wallet-monogram-empreinte-nvprod1600155v#M67839",
            tag: "classic",
            placeholder_url: "https://via.placeholder.com/300",
            image_urls: [
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=216&hei=216",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=320&hei=320",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=456&hei=456",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=656&hei=656",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=824&hei=824",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=1080&hei=1080",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=1240&hei=1240",
                "https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-vavin-chain-wallet-monogram-empreinte-leather-small-leather-goods--M67839_PM2_Front%20view.png?wid=2048&hei=2048"
            ],
            image_id: 69
        }
    ],
    questions: [
        {
            id: 2,
            number: 1,
            name: 'Question 1',
            answers: [
                {
                    id: 1,
                    name: 'playful'
                },
                {
                    id: 2,
                    name: 'elegant'
                }
            ],
            generated_by: null
        },
        {
            id: 21,
            number: 2,
            name: 'Question 2-1',
            answers: [
                {
                    id: 1,
                    name: 'bold'
                },
                {
                    id: 2,
                    name: 'minimalist'
                }
            ],
            generated_by: 21
        },
        {
            id: 22,
            number: 2,
            name: 'Question 2-2',
            answers: [
                {
                    id: 1,
                    name: 'bold'
                },
                {
                    id: 2,
                    name: 'minimalist'
                }
            ],
            generated_by: 22
        },
        {
            id: 31,
            number: 3,
            name: 'Question 3-1',
            answers: [
                {
                    id: 1,
                    name: 'statement'
                },
                {
                    id: 2,
                    name: 'classic'
                }
            ],
            generated_by: 211
        },
        {
            id: 32,
            number: 3,
            name: 'Question 3-2',
            answers: [
                {
                    id: 1,
                    name: 'statement'
                },
                {
                    id: 2,
                    name: 'classic'
                }
            ],
            generated_by: 212
        },
        {
            id: 33,
            number: 3,
            name: 'Question 3-3',
            answers: [
                {
                    id: 1,
                    name: 'statement'
                },
                {
                    id: 2,
                    name: 'classic'
                }
            ],
            generated_by: 221
        },
        {
            id: 34,
            number: 3,
            name: 'Question 3-4',
            answers: [
                {
                    id: 1,
                    name: 'statement'
                },
                {
                    id: 2,
                    name: 'classic'
                }
            ],
            generated_by: 222
        },
        {
            id: 41,
            number: 4,
            name: 'Question 4-1',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 311
        },
        {
            id: 42,
            number: 4,
            name: 'Question 4-2',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 312
        },
        {
            id: 43,
            number: 4,
            name: 'Question 4-3',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 321
        },
        {
            id: 44,
            number: 4,
            name: 'Question 4-4',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 322
        },
        {
            id: 45,
            number: 4,
            name: 'Question 4-5',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 331
        },
        {
            id: 46,
            number: 4,
            name: 'Question 4-6',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 332
        },
        {
            id: 47,
            number: 4,
            name: 'Question 4-7',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 341
        },
        {
            id: 48,
            number: 4,
            name: 'Question 4-8',
            answers: [
                {
                    id: 1,
                    name: 'country'
                },
                {
                    id: 2,
                    name: 'city'
                }
            ],
            generated_by: 342
        },
        {
            id: 51,
            number: 5,
            name: 'Question 5-1',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 411
        },
        {
            id: 52,
            number: 5,
            name: 'Question 5-2',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 412
        },
        {
            id: 53,
            number: 5,
            name: 'Question 5-3',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 421
        },
        {
            id: 54,
            number: 5,
            name: 'Question 5-4',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 422
        },
        {
            id: 55,
            number: 5,
            name: 'Question 5-5',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 431
        },
        {
            id: 56,
            number: 5,
            name: 'Question 5-6',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 432
        },
        {
            id: 57,
            number: 5,
            name: 'Question 5-7',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 441
        },
        {
            id: 58,
            number: 5,
            name: 'Question 5-8',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 442
        },
        {
            id: 59,
            number: 5,
            name: 'Question 5-9',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 451
        },
        {
            id: 510,
            number: 5,
            name: 'Question 5-10',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 452
        },
        {
            id: 511,
            number: 5,
            name: 'Question 5-11',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 461
        },
        {
            id: 512,
            number: 5,
            name: 'Question 5-12',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 462
        },
        {
            id: 513,
            number: 5,
            name: 'Question 5-13',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 471
        },
        {
            id: 514,
            number: 5,
            name: 'Question 5-14',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 472
        },
        {
            id: 515,
            number: 5,
            name: 'Question 5-15',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 481
        },
        {
            id: 516,
            number: 5,
            name: 'Question 5-16',
            answers: [
                {
                    id: 1,
                    name: 'day'
                },
                {
                    id: 2,
                    name: 'evening'
                }
            ],
            generated_by: 482
        }
    ]
}
