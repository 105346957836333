<template>
  <div class="home">
<!--    <transition-->
<!--        v-if="!quizInitialized"-->
<!--        name="slide-fade">-->
<!--      <landing />-->
<!--    </transition>-->
    <div
        class="questions-title">
      {{ questionTitle }}
    </div>
    <div
        v-for="question in $store.getters.allQuestions"
        :key="question.id">
      <transition
          @enter="changeBackground"
          appear
          name="slide-fade">
        <questions
            v-if="question.id === currentQuestion.id"
            :question="currentQuestion"/>
      </transition>
    </div>
  </div>
</template>

<script>
import Questions from '/src/components/Questions.vue';
// import Landing from '/src/components/Landing.vue';

export default {
  components: {
    Questions,
    // Landing
  },
  computed: {
    quizInitialized() {
      return this.$store.getters.quizInitialized;
    },
    currentQuestion() {
      return this.$store.getters.currentQuestion;
    },
    questionTitle() {
      return 'please select one';
    }
  },
  methods: {
    changeBackground() {
      let color;

      [1,3,5].includes(this.currentQuestion.number) ?
          color = '180deg, #F2ECEB 20.34%, #EDE4E2 38.53%, #DECDC8 68.79%, #C7A89F 107.13%, #B99286 127.73%' :
          color = '180deg, #DEEAEB 20.34%, #D5E3E5 39.05%, #BBCFD4 70.16%, #9BB7BE 101.63%';

      document.getElementById('app').style.background = `linear-gradient(${color})`;
      document.getElementById('app').style.filter = 'saturate(115%)';
    }
  }
}
</script>

<style scoped>
.home {
  padding-top: 50px;
}
.questions-title {
  position: fixed;
  top: 20vh;
  width: 100%;
  height: 50px;
  font-weight: bold;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .questions-title {
    top: 15vh;
  }
}
</style>
