<template>
  <div id="app">
    <transition name="slide-fade">
        <router-view class="full-height" />
    </transition>
    <app-header />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";

export default {
  components: { AppHeader }
}
</script>

<style>
@font-face {
  font-family: "LouisVuitton-Light";
  src: url("/fonts/LouisVuitton-Light.otf");
}
@font-face {
  font-family: "LouisVuitton-Medium";
  src: url("/fonts/LouisVuitton-Medium.otf");
}
@font-face {
  font-family: "LouisVuitton-Regular";
  src: url("/fonts/LouisVuitton-Regular.otf");
}
@font-face {
  font-family: "LouisVuitton-Demi";
  src: url("/fonts/LouisVuitton-Demi.otf");
}
@font-face {
  font-family: "LouisVuitton-Bold";
  src: url("/fonts/LouisVuitton-Bold.otf");
}

html {
  height: 100%;
}
body {
  height: 100%;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: LouisVuitton-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.slide-fade-enter-active {
  transition: all 1.2s ease;
}
.slide-fade-leave-active {
  position: absolute;
  height: 100%;
  width: 100%;
}
.slide-fade-enter {
  transform: translateY(100vh);
}
.slide-fade-leave-to {
  transition: all 1.2s ease;
  transform: translateY(-100vh);
}
</style>
