<template>
  <div class="products">
    <img
        class="products-banner"
        :src="productsBanner"
        alt="Products Banner">
    <div class="products-wrapper">
      <div
          v-for="(productGroup, index) in productGroups"
          :key="`group-${index}`"
          class="card-deck">
        <div v-for="product in productGroup"
             :key="product.id"
             @click="
                redirect(product.product_url, true);
                logAnalytics({ 'product': product, 'offset': index })"
             class="card">
          <img
              class="card-img-top"
              :src="
                product.image_id && `/images/products/${product.image_id}.jpg` ||
                product.image_urls[4] ||
                product.placeholder_url"
              alt="Card image cap">
          <div class="card-body">
            <p class="card-text">{{ product.name }}</p>
            <p class="card-text price">{{ product.price }}</p>
          </div>
        </div>
        <!-- Compensate for missing products in the row -->
        <div v-if="productGroup.length <= 2 && cardsInRow > 2" class="card" style="cursor: unset !important;" />
        <div v-if="productGroup.length === 1 && cardsInRow === 2" class="card" style="cursor: unset !important;" />
        <div v-if="productGroup.length === 1 && cardsInRow > 2" class="card" style="cursor: unset !important;" />
      </div>
      <div style="display: flex; flex-direction: row; justify-content: center">
        <div
            class="start-again-btn mr-3"
            style="align-self: flex-end"
            @click="redirect('home')">
          Start Over
        </div>
        <div
            class="start-again-btn ml-4"
            @click="redirect('https://us.louisvuitton.com/eng-us/women/gifts-for-women/gift-selection-for-women/_/N-wa8bfc', true)">
          Explore More Gifts
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardsInRow: 3,
      productsBanner: '/images/products/products-banner.jpg'
    }
  },
  mounted() {
    if (this.$route.path.includes('products')) {
      this.$gtag.pageview({
        'page_path': 'welvmomsproducts',
        'page_title': `products`,
        'cd2': 'louisvuitton.com',
        'cd4': 'site',
        'cd5': 'us',
        'cd9': 'not_logged_user',
        'cd12': 'vue_stack',
        'cd84': 'welvmoms_module'
      });
    }

    this.changeRows();
    this.generateProductsBanner();
    window.addEventListener('resize', this.changeRows);
    window.addEventListener('resize', this.generateProductsBanner);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeRows);
    window.removeEventListener('resize', this.generateProductsBanner);
  },
  computed: {
    productGroups() {
      return this.generateProductGroups(this.$store.state.products, this.cardsInRow)
    }
  },
  methods: {
    generateProductGroups(products, numberOfGroups) {
      return products
          .filter(product => this.$store.getters.allAnswers.includes(product.tag))
          .reduce((r, e, i) => (i % numberOfGroups ? r[r.length - 1].push(e) : r.push([e])) && r, []);
    },
    changeRows() {
      // desktop
      if (window.innerWidth > 960) {
        this.cardsInRow = 3;
      }
      // tablet
      // if (window.innerWidth <= 960) {
      //   this.cardsInRow = 2;
      // }
      // phone
      if (window.innerWidth <= 768) {
        this.cardsInRow = 2;
      }
    },
    redirect(url, newTab = false) {
      newTab ?
          window.open(url) :
          window.location = url;
    },
    logAnalytics(data) {
      // analytics - product selected
      this.$gtag.event('product_selection', {
        'event_category': 'welvmoms',
        'event_label': data.product.id,

        /*
          Might have something to do with e-commerce related analytics,
          see: https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
        */
        'pr1id': data.product.id,
        'pr1ps': data.offset,
        'pa': 'click',

        'cd27': data.product.id,
        'cd2': 'louisvuitton.com',
        'cd4': 'site',
        'cd5': 'us',
        'cd9': 'not_logged_user',
        'cd12': 'vue_stack',
      });
    },
    generateProductsBanner() {
      window.innerWidth <= 960 ?
          this.productsBanner = '/images/products/products-banner-small.jpg' :
          this.productsBanner = '/images/products/products-banner.jpg';
    }
  }
}
</script>

<style scoped>
.products {
  background-color: #ffffff;
  height: 100%;
}
.products-wrapper {
  padding: 30px 30px;
}
.products-banner {
  width: 100%;
  margin-top: 50px;
}
.card-deck {
  margin-bottom: 20px;
}
.card {
  border-radius: 0;
  border: none;
}
.card:hover {
  cursor: pointer;
}
.card-img-top {
  border-radius: 0;
}
.card-body {
  padding-left: 0;
}
.card-body p {
  margin-bottom: 0;
}
.card-text {
  font-family: LouisVuitton-Medium, sans-serif;
  text-transform: capitalize;
  text-align: left;
  font-size: 14px;
}
.card-text.price {
  font-family: LouisVuitton-Light, sans-serif;
}
.start-again-btn {
  height: 40px;
  width: 150px;
  background-color: black;
  color: white;
  line-height: 40px;
  /*margin: 0 auto;*/
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .products-wrapper {
    padding: 30px 5%;
  }
  .card-deck {
    display: flex;
    justify-content: space-between;
  }
  .card-img-top {
    background-color: rgb(246, 245, 243);
  }
}
@media screen and (min-width: 768px) {
  .card-img-top:hover {
    background-color: rgb(246, 245, 243);
  }
}
@media screen and (max-width: 500px) {
  .card-deck .card {
    flex: 0 1 48%;
  }
}
@media screen and (min-width: 500px) and (max-width: 576px) {
  .card-deck .card {
    flex: 0 1 47%;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .card-deck .card {
    flex: 0 1 44.5%;
  }
}
</style>
