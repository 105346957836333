import Data from "./components/Data";

export const state = {
    currentQuestionId: 2,
    quizInitialized: false,
    isQuizFinished: false,
    answers: [],
    questions: Data.questions,
    products: Data.products
};

export const mutations = {
    setAnswer(state, answer) {
        state.answers.push(answer);
    },
    setCurrentQuestionId(state, questionId) {
        state.currentQuestionId = questionId;
    },
    setQuizFinished(state, val) {
        state.isQuizFinished = val;
    },
    setQuizInitialized(state, val) {
        state.quizInitialized = val;
    }
};

export const getters = {
    quizInitialized: state => state.quizInitialized,
    question: state => id => state.questions.find(question => question.id === id),
    generatedQuestion: state => generatedBy => state.questions.find(question => question.generated_by === generatedBy),
    currentQuestion: (state) => state.questions.find(question => question.id === state.currentQuestionId),
    allQuestions: (state) => state.questions,
    allAnswers: (state) => state.answers
};

export const actions = {
    submitAnswer({commit, getters, state}, data) {
        const answer = getters.question(data.questionId).answers.find(ans => ans && ans.id && ans.id === data.answerId).name;

        if (!state.answers.includes(answer)) {
            commit('setAnswer', answer);
        } else {
            return 'You already answered that question';
        }
    },
    generateNextQuestion({commit, state}, questionId) {
        if (state.currentQuestionId !== questionId) {
            commit('setCurrentQuestionId', questionId);
        } else {
            return 'Invalid question'
        }
    },
    toggleQuizFinished({commit, state}) {
        commit('setQuizFinished', !state.isQuizFinished);
    },
    toggleQuizInit({commit, state}) {
        commit('setQuizInitialized', !state.quizInitialized);
    }
};

export default function() {
    return {
        strict: process.env.NODE_ENV !== 'production',
        state,
        mutations,
        getters,
        actions
    };
}
