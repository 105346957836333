import App from './App.vue';
import Home from './components/Home.vue';
import Products from './components/Products.vue';
import ErrorPages from './components/ErrorPages.vue';

export default [
    {
        path: '/',
        component: App,
        name: 'app',
        redirect: '/home',
        props: true,
        children: [
            {
                path: 'home',
                component: Home,
                name: 'home'
            },
            {
                path: 'products',
                component: Products,
                name: 'products',
                params: {
                    quizFinished: false
                }
            },
        ]
    },
    {
        path: '*',
        component: ErrorPages,
        props: () => ({
            status: 404
        })
    }
];
